import { ClipboardCheckIcon, ClipboardIcon } from "@heroicons/react/outline";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { Input } from "../../../components/InputFields";
import { SidebarLayout } from "../../../components/Layout";
import SettingsSidebar from "../../../components/SettingsSidebar";
import { addMessage } from "../../../state/reducers/appReducer";
import { updateRecoveryCodesGenerated } from "../../../state/reducers/settingsReducer";
import { iconProps } from "../../../styles/settings";
import { fetchBaseOptions } from "../../../utils/fetch";
import { passwordValidator } from "../../../utils/validators";

const EnableTotpSchema = yup.object().shape({
  password: passwordValidator,
});

interface IAPIResponseRecovery extends IAPIResponse {
  recoveryCodes?: string[];
}

const RecoveryCodesPage = () => {
  const dispatch = useDispatch();
  const [recoveryCodes, setRecoveryCodes] = useState<string[]>([]);
  const [copied, setCopied] = useState(false);

  const copyCodes = e => {
    e.preventDefault();
    navigator.clipboard.writeText(recoveryCodes.join("\n")).then(() => setCopied(true));
  };

  return (
    <SidebarLayout seo={{ title: "Recovery codes" }} sidebar={<SettingsSidebar />}>
      <div className="space-y-4">
        <p>
          On this page you can generate recovery codes in case you lose access to any of your two
          factor authentication methods. Once you have used a code, it won't work again.
        </p>
        <p>
          You can generate a new set of recovery codes at any time. Keep in mind that the old ones
          will stop working
        </p>
        {recoveryCodes.length > 0 ? (
          <ul className="relative p-4 mx-auto font-mono rounded shadow bg-neutral-100 w-max">
            {recoveryCodes.map(r => (
              <li>{r}</li>
            ))}
            <button
              onClick={copyCodes}
              className="absolute bottom-0 right-0 p-2 bg-white rounded-full shadow-lg focus:ring hover:shadow-sm"
            >
              {copied ? (
                <ClipboardCheckIcon
                  aria-label="Recovery codes copied"
                  className={iconProps.className}
                />
              ) : (
                <ClipboardIcon aria-label="Copy recovery codes" className={iconProps.className} />
              )}
            </button>
          </ul>
        ) : (
          <Formik
            initialValues={{ password: "" }}
            validationSchema={EnableTotpSchema}
            onSubmit={(values, { setSubmitting }) => {
              fetch("/api/2fa/recovery/generate", {
                ...fetchBaseOptions,
                body: JSON.stringify({ password: values.password }),
              })
                .then(async res => {
                  const j: IAPIResponseRecovery = await res.json();
                  dispatch(addMessage(j));
                  if (res.ok) {
                    setRecoveryCodes(j.recoveryCodes);
                    dispatch(updateRecoveryCodesGenerated(true));
                  }
                })
                .catch(err => console.error(err))
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting, isValid }) => (
              <Form className="mt-8 space-y-2">
                <Field
                  required={true}
                  type="password"
                  name="password"
                  label="Password"
                  component={Input}
                />
                <button
                  className="w-full btn btn-primary"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                >
                  Generate recovery codes
                </button>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </SidebarLayout>
  );
};

export default RecoveryCodesPage;
