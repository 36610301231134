import * as yup from "yup";

export const passwordValidator = yup
  .string()
  .min(6, "Too Short!")
  .max(128, "Too Long!")
  .required("Required");

export const repeatPasswordValidator = passwordValidator.oneOf(
  [yup.ref("password"), null],
  "Passwords must match",
);

export const emailValidator = yup.string().email("Invalid email").required("Required");

export const LoginSchema = yup.object().shape({
  email: emailValidator,
  password: passwordValidator,
});
export const EmailSchema = yup.object().shape({ email: emailValidator });
export const PasswordSchema = yup.object().shape({ password: passwordValidator });
